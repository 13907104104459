import { Component } from 'react';
import PropTypes from 'prop-types';
import * as tracking from 'dibs-tracking';
import get from 'lodash/get';

import CarouselWrapper from './CarouselWrapper';
import MediaCarouselItem from './MediaCarouselItem';
import MobileCarouselWrapper from './MobileCarouselWrapper';

import styles from './styles.scss';

class MediaCarousel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentIndex: 0,
        };

        this.onNext = this.onNext.bind(this);
        this.onPrevious = this.onPrevious.bind(this);
    }

    getOrderedMediaList() {
        const mediaList = get(this.props, 'mediaList') || [];
        // Sorting the media list based on the sort order
        return mediaList.slice().sort((a, b) => {
            const A = Number(a.sortOrder);
            const B = Number(b.sortOrder);
            // returns -1 if A < B, 1 if A > B and 0 if A = B
            return (A - B) / Math.abs(A - B);
        });
    }

    onNext() {
        this.updateIndex(this.state.currentIndex + 1, 'next');
    }

    onPrevious() {
        this.updateIndex(this.state.currentIndex - 1, 'previous');
    }

    updateIndex(newIndex, action) {
        const length = get(this.props, 'mediaList.length') || 0;

        if (length > 1) {
            if (newIndex < 0) {
                newIndex = length - 1;
            } else if (newIndex >= length) {
                newIndex = 0;
            }
            this.setState({ currentIndex: newIndex });
            tracking.trackEvent({
                category: 'dealer storefront',
                action: `dealer image carousel ${action} button`,
            });
        }
    }

    renderCarouselItems({ currentIndex, mediaList, useSrcSet }) {
        return (
            <div className={styles.carouselItems}>
                {/* Carousel Items */}
                {mediaList.map((mediaItem, index) => {
                    const {
                        element: { serviceId, ...element },
                    } = mediaItem;
                    return (
                        <MediaCarouselItem
                            key={serviceId}
                            element={element}
                            useSrcSet={useSrcSet}
                            isActive={index === currentIndex}
                        />
                    );
                })}
            </div>
        );
    }

    render() {
        const { isMobile, useSrcSet } = this.props;
        const currentIndex = this.state.currentIndex;
        const mediaList = this.getOrderedMediaList();
        const transform = `translateX(-${currentIndex * 100}%)`;

        const disable = mediaList.length === 1;
        const Wrapper = isMobile ? MobileCarouselWrapper : CarouselWrapper;

        return (
            <Wrapper disable={disable} onNext={this.onNext} onPrevious={this.onPrevious}>
                {/* Carousel */}
                <div className={styles.carousel} style={{ transform }}>
                    {this.renderCarouselItems({ currentIndex, mediaList, useSrcSet })}
                </div>
            </Wrapper>
        );
    }
}

MediaCarousel.defaultProps = {
    useSrcSet: false,
};

MediaCarousel.propTypes = {
    isMobile: PropTypes.bool.isRequired,
    useSrcSet: PropTypes.bool.isRequired,
    mediaList: PropTypes.arrayOf(
        PropTypes.shape({
            sortOrder: PropTypes.string.isRequired,
            element: PropTypes.shape({
                serviceId: PropTypes.string.isRequired,
                url: PropTypes.string,
                path: PropTypes.string,
                caption: PropTypes.string,
                mediaType: PropTypes.oneOf(['PHOTO', 'VIDEO']).isRequired,
            }).isRequired,
        }).isRequired
    ).isRequired,
};

export default MediaCarousel;
