import { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { FormattedMessage } from 'dibs-react-intl';
import { StarRating } from 'dibs-elements/exports/StarRating';
import { SellerReviewsModalAsync } from 'dibs-seller-reviews-modal/exports/SellerReviewsModalAsync';
import { StorefrontDetailsDistinction } from './StorefrontDetailsDistinction';

import get from 'lodash/get';
import classNames from 'classnames';
import styles from './styles/StorefrontDetails.scss';

import PinIcon from 'dibs-icons/exports/legacy/LocationPin';
import RecognizedIcon from 'dibs-icons/exports/legacy/RecognizedRibbon';
import DiamondIcon from 'dibs-icons/exports/legacy/Diamond';
import FilledDiamondIcon from 'dibs-icons/exports/legacy/DiamondFilled';

function getClasses({ details }) {
    return {
        ...styles,
        details: classNames(details, styles.details),
    };
}

class StorefrontDetails extends Component {
    constructor(props) {
        super(props);
        this.state = { isReviewsModalOpen: false };

        this.openModal = this.openModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
    }

    openModal() {
        this.setState({ isReviewsModalOpen: true });
    }

    onCloseModal() {
        this.setState({ isReviewsModalOpen: false });
    }

    render() {
        const { additionalClasses, seller, relay } = this.props;
        const { isReviewsModalOpen } = this.state;
        const classes = getClasses(additionalClasses);
        const isGoldTier = get(seller, 'sellerTiers.isGoldTier');
        const isPlatinumTier = get(seller, 'sellerTiers.isPlatinumTier');
        const isDistinguished = get(seller, 'isDistinguished');
        const isRewardsTier2 = get(seller, 'isRewardsTier2');
        let sellerTier = null;
        let feedbackRating = get(
            seller,
            'orderFeedbackPdp.metadata.aggregatedFeedback.overallExperience.mean'
        );
        feedbackRating = feedbackRating ? parseFloat(feedbackRating.toFixed(1)) : 0;

        let tierTitle = null;
        let customIcon = null;
        let customStyles = null;
        if (isRewardsTier2) {
            sellerTier = 'diamond';
            customIcon = FilledDiamondIcon;
            tierTitle = (
                <FormattedMessage
                    id="StorefrontDetails.diamondTierTitle"
                    defaultMessage="Diamond"
                />
            );
        } else if (isGoldTier) {
            sellerTier = 'gold';
            customStyles = styles.goldDiamond;
            tierTitle = (
                <FormattedMessage id="StorefrontDetails.goldTierTitle" defaultMessage="Gold" />
            );
        } else if (isPlatinumTier) {
            sellerTier = 'platinum';
            customStyles = styles.platinumDiamond;
            tierTitle = (
                <FormattedMessage
                    id="StorefrontDetails.platinumTierTitle"
                    defaultMessage="Platinum"
                />
            );
        }

        return (
            <div className={classes.details}>
                <div className={classes.nameWrapper}>
                    {/* Company Name */}
                    <h1 data-tn="storefront-details-company-name" className={classes.companyName}>
                        {get(seller, 'sellerProfile.company')}
                    </h1>
                    {/* Recognized Dealer ? */}
                    {isDistinguished && (
                        <StorefrontDetailsDistinction
                            icon={RecognizedIcon}
                            title={
                                <FormattedMessage
                                    id="StorefrontDetails.distinguised"
                                    defaultMessage="Recognized"
                                />
                            }
                            description={
                                <FormattedMessage
                                    id="StorefrontDetails.recognized"
                                    defaultMessage="This designation is for sellers whose items consistently exemplify the best of 1stDibs."
                                />
                            }
                            dataTn="storefront-details-distinguished"
                        />
                    )}
                    {/* isGoldTier or isPlatinumTier ? */}
                    {(isRewardsTier2 || isGoldTier || isPlatinumTier) && (
                        <StorefrontDetailsDistinction
                            icon={customIcon ? customIcon : DiamondIcon}
                            customIconStyles={customStyles}
                            title={tierTitle}
                            description={
                                <FormattedMessage
                                    id="StorefrontDetails.topSeller"
                                    defaultMessage="This distinction is for experienced sellers who continually surpass customer expectations."
                                />
                            }
                            dataTn={`storefront-details-${sellerTier}-tier`}
                        />
                    )}
                </div>

                {/* Seller Stats */}
                {feedbackRating > 0 ? (
                    <Fragment>
                        <div className={classes.sellerStats} onClick={this.openModal}>
                            <StarRating rating={feedbackRating} size="small" />
                            <div className={classes.ratingFraction}>{`${feedbackRating} / 5`}</div>
                        </div>
                        <SellerReviewsModalAsync
                            environment={relay.environment}
                            isOpen={isReviewsModalOpen}
                            onClose={this.onCloseModal}
                            sellerId={get(seller, 'serviceId')}
                        />
                    </Fragment>
                ) : null}

                {/* Location */}
                <div className={classes.locationModule}>
                    <PinIcon className={classes.locationPin} />
                    <span data-tn="storefront-details-location" className={classes.location}>
                        {get(seller, 'shippingAddress.displayCityStateCountry')}
                    </span>
                </div>
            </div>
        );
    }
}

StorefrontDetails.propTypes = {
    relay: PropTypes.object,
    additionalClasses: PropTypes.shape({
        details: PropTypes.string.isRequired,
    }).isRequired,

    seller: PropTypes.object.isRequired,
};

export default createFragmentContainer(StorefrontDetails, {
    seller: graphql`
        fragment StorefrontDetails_seller on Seller {
            serviceId
            isDistinguished
            isRewardsTier2: isAnchorListMember(listName: "SELLER_REWARDS_2")
            sellerProfile {
                company
            }
            shippingAddress {
                displayCityStateCountry
            }
            orderFeedbackPdp {
                metadata {
                    aggregatedFeedback {
                        overallExperience {
                            mean
                        }
                    }
                }
            }
            sellerTiers {
                isGoldTier
                isPlatinumTier
            }
        }
    `,
});
