import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import get from 'lodash/get';

import StorefrontHead from './head/StorefrontHead';
import StorefrontHome from './home/StorefrontHome';

function StorefrontLayout({ isPreview, ...props }) {
    const user = get(props, 'viewer.user') || null;
    const seller = get(props, 'viewer.seller') || null;
    const storefrontProfile = get(props, 'viewer.seller.storefrontProfile') || null;
    const storefrontData = get(props, 'viewer.storefrontData');

    return (
        <Fragment>
            <div className="primary-viewport-width">
                <StorefrontHead
                    seller={seller}
                    user={user}
                    storefrontProfile={storefrontProfile}
                    isPreview={isPreview}
                    viewer={props.viewer}
                />
            </div>
            <StorefrontHome
                isPreview={isPreview}
                storefrontProfile={storefrontProfile}
                sellerData={props.sellerData}
                user={user}
                seller={seller}
                storefrontData={storefrontData}
                viewer={props.viewer}
                isTrade={props.isTrade}
            />
        </Fragment>
    );
}

StorefrontLayout.propTypes = {
    viewer: PropTypes.object.isRequired,
    isPreview: PropTypes.bool.isRequired,
    isTrade: PropTypes.bool.isRequired,
    sellerData: PropTypes.shape({
        dealerSince: PropTypes.string.isRequired,
    }).isRequired,
};

export default createFragmentContainer(StorefrontLayout, {
    viewer: graphql`
        fragment StorefrontLayout_viewer on Viewer {
            user(userId: $userId) @include(if: $hasUserId) {
                ...StorefrontHead_user
                ...StorefrontHome_user
            }
            seller(sellerId: $sellerId) {
                ...StorefrontHead_seller
                ...StorefrontHome_seller
            }
            storefrontData: dealerStorefront(dealer: $urlLabel, sellerId: $sellerId, first: 12) {
                ...StorefrontHome_storefrontData
            }
            ...StorefrontHead_viewer
            ...StorefrontHome_viewer
        }
    `,
});
