import PropTypes from 'prop-types';
import { graphql, createFragmentContainer } from 'react-relay/legacy';
import { FormattedMessage } from 'dibs-react-intl';

import get from 'lodash/get';

function StorefrontFollowButton(props) {
    const favoriteId = get(props, 'seller.favoritesByUser[0].serviceId');
    return (
        <div
            data-tn="storefront-head-follow-button"
            className={props.className}
            onClick={props.followDealer}
        >
            {favoriteId ? (
                <FormattedMessage
                    id="StorefrontFollowButton.unfollowCta"
                    defaultMessage="Following"
                />
            ) : (
                <FormattedMessage id="StorefrontFollowButton.followCta" defaultMessage="Follow" />
            )}
        </div>
    );
}

StorefrontFollowButton.propTypes = {
    className: PropTypes.string,
    followDealer: PropTypes.func.isRequired,
    seller: PropTypes.object.isRequired,
};

export default createFragmentContainer(StorefrontFollowButton, {
    seller: graphql`
        fragment StorefrontFollowButton_seller on Seller {
            serviceId
            favoritesByUser(userIds: $userId) @include(if: $hasUserId) {
                serviceId
            }
        }
    `,
});
