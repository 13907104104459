import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { FormattedMessage } from 'dibs-react-intl';

import get from 'lodash/get';
import { getAddressLines, storeAddressByAppointmentOnly } from '../helpers/addressPreferences';

import { ModalContainer } from 'dibs-elements/exports/ModalContainer';
import { BarHeader } from 'dibs-elements/exports/BarHeader';
import { ModalBody } from 'dibs-elements/exports/ModalBody';
import { ModalCloseButton } from 'dibs-elements/exports/ModalCloseButton';

import styles from './styles/StorefrontContactModal.scss';

function getAssociationNames(seller) {
    const tradeAssociations = get(seller, 'tradeAssociations') || [];
    const associationNames = tradeAssociations.reduce((_associationNames, association) => {
        const displayName = get(association, 'displayName');
        if (displayName) {
            _associationNames.push(displayName);
        }
        return _associationNames;
    }, []);

    return associationNames.length ? associationNames : null;
}

function Address({ addressLines, byAppointmentOnly }) {
    return (
        <div className={styles.address}>
            <p className={styles.sectionHeader}>
                <FormattedMessage id="StorefrontContactModal.address" defaultMessage="Address" />
            </p>
            {/* Address */}
            {addressLines ? (
                <div className={styles.addressLines} data-tn="address-line">
                    {addressLines.map(addressLine => (
                        <p key={addressLine}>{addressLine}</p>
                    ))}
                </div>
            ) : null}

            {/* By Appointment Only */}
            {byAppointmentOnly ? (
                <p className={styles.byAppointmentOnly} data-tn="appointment-only">
                    <FormattedMessage
                        id="StorefrontContactModal.byAppointmentOnly"
                        defaultMessage="By Appointment Only"
                    />
                </p>
            ) : null}
        </div>
    );
}
Address.propTypes = {
    addressLines: PropTypes.arrayOf(PropTypes.string.isRequired),
    byAppointmentOnly: PropTypes.bool,
};

function Associations({ associationNames }) {
    return (
        <div className={styles.associations} data-tn="associations-component">
            <p className={styles.sectionHeader}>
                <FormattedMessage
                    id="StorefrontContactModal.associations"
                    defaultMessage="All Associations"
                />
            </p>
            {associationNames.map(displayName => (
                <p key={displayName}>{displayName}</p>
            ))}
        </div>
    );
}
Associations.propTypes = {
    associationNames: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

function StorefrontContactModal({ seller, isOpen, closeModal }) {
    const addressLines = getAddressLines(seller);
    const associationNames = getAssociationNames(seller);
    const byAppointmentOnly = storeAddressByAppointmentOnly(seller);

    return (
        <ModalContainer modalPosition="center" isOpen={isOpen} onClose={closeModal}>
            <ModalCloseButton onClick={closeModal} />
            <BarHeader
                title={
                    <FormattedMessage
                        id="StorefrontContactModal.header"
                        defaultMessage="Contact Info"
                    />
                }
            />
            <ModalBody>
                <div className={styles.content}>
                    <Address addressLines={addressLines} byAppointmentOnly={byAppointmentOnly} />

                    {associationNames ? <Associations associationNames={associationNames} /> : null}
                </div>
            </ModalBody>
        </ModalContainer>
    );
}

StorefrontContactModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    seller: PropTypes.object,
    closeModal: PropTypes.func.isRequired,
};

export default createFragmentContainer(StorefrontContactModal, {
    seller: graphql`
        fragment StorefrontContactModal_seller on Seller {
            sellerPreferences {
                displayStoreAddress
                storeAddressByAppointmentOnly
            }
            sellerProfile {
                company
            }
            shippingAddress {
                line1
                line2
                displayCountry
                displayCityStateZip
            }
            tradeAssociations {
                displayName
            }
        }
    `,
});
