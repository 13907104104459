import get from 'lodash/get';

/**
 * Returns the seller's dedicated customer line
 *
 * @param seller
 * @returns {*|null}
 */
export function getDedicatedPhoneNumber(seller) {
    return get(seller, 'trackedPhone.phoneNumber') || null;
}

/**
 * Returns true/false if the seller has a dedicated customer line phone number
 * @param seller
 * @returns {boolean}
 */
export function hasPhoneNumber(seller) {
    return !!getDedicatedPhoneNumber(seller);
}

/**
 * Returns true/false if the seller has opted to show their store address
 * @param seller
 * @returns {boolean}
 */
export function displayStoreAddress(seller) {
    // if null => default to true
    return get(seller, 'sellerPreferences.displayStoreAddress') !== false;
}

/**
 * Returns true/false if the seller operates by appointment only
 * @param seller
 * @returns {boolean}
 */
export function storeAddressByAppointmentOnly(seller) {
    // if null => default to false
    return get(seller, 'sellerPreferences.storeAddressByAppointmentOnly') === true;
}

/**
 * Don't render contact info button if phone number, show address and by appointment
 *  are all false (FINDING-10189)
 * @param seller
 * @returns {boolean}
 */
export function shouldRenderContactModal(seller) {
    return displayStoreAddress(seller) || storeAddressByAppointmentOnly(seller);
}

/**
 * Returns an array of address line strings (if the dealer has opted to show their address)
 *
 * @param seller
 * @returns {array|null}
 */
export function getAddressLines(seller) {
    let addressLines = [];

    if (displayStoreAddress(seller)) {
        const shipping1 = get(seller, 'shippingAddress.line1');
        const shipping2 = get(seller, 'shippingAddress.line2');

        addressLines = [
            get(seller, 'sellerProfile.company'),
            shipping1 ? shipping1 + (shipping2 ? `, ${shipping2}` : '') : '',
            get(seller, 'shippingAddress.displayCityStateZip'),
            get(seller, 'shippingAddress.displayCountry'),
        ].filter(Boolean);
    }

    return addressLines.length ? addressLines : null;
}
