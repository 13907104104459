import { Component } from 'react';
import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import get from 'lodash/get';
import { storefrontEnsureLogin } from '../mutations/storefrontEnsureLogin';
import { storefrontFollowDealer } from '../mutations/storefrontFollowDealer';
import { trackFollowSearch } from '../../sharedComponents/FollowButton/followSearchTracking';
import { getImageSrc } from 'dibs-buyer-layout/exports/imageHelpers';

import StorefrontSocial from './StorefrontSocial';
import StorefrontDetails from './StorefrontDetails';
import StorefrontHeadshot from './StorefrontHeadshot';
import StorefrontFollowButton from './StorefrontFollowButton';
import ProfileCover from 'dibs-profile-cover';

import {
    DEALER_STOREFRONT_BANNER,
    DEALER_STOREFRONT_HEADSHOT,
    HEADSHOT_SMALL,
    HEADSHOT_LARGE,
    HEADSHOT_MOBILE_BREAKPOINT,
} from '../helpers/constants';

import styles from './styles/StorefrontHead.scss';

function getPhotoPath(props, type) {
    const photos = get(props, 'seller.storefrontProfile.photos') || [];
    const photoObj = photos.find(photo => {
        return get(photo, 'type.name') === type;
    });

    return get(photoObj, 'path') || '';
}

function getClasses(keys = []) {
    return keys.reduce(
        (classes, key) => ({
            ...classes,
            [key]: styles[key],
        }),
        {}
    );
}

function getHeadshotSize(winW) {
    if (winW > HEADSHOT_MOBILE_BREAKPOINT) {
        return HEADSHOT_LARGE;
    }
    return HEADSHOT_SMALL;
}

class StorefrontHead extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headshotSize: HEADSHOT_LARGE,
        };

        this.updateHeadshotSize = this.updateHeadshotSize.bind(this);
        this.followDealer = this.followDealer.bind(this);
    }

    componentDidMount() {
        this.updateHeadshotSize();
        window.addEventListener('resize', this.updateHeadshotSize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateHeadshotSize);
    }

    /**
     *  Updates the 'headshotSize' prop based on the window width
     *  (smaller headshot on mobile)
     */
    updateHeadshotSize() {
        const winW = window.innerWidth;
        if (!winW) {
            return;
        }

        const newHeadshotSize = getHeadshotSize(winW);
        const oldHeadshotSize = this.state.headshotSize;

        if (newHeadshotSize !== oldHeadshotSize) {
            this.setState({ headshotSize: newHeadshotSize });
        }
    }

    followDealer() {
        const favoriteId = get(this.props, 'seller.favoritesByUser[0].serviceId');
        storefrontEnsureLogin({
            relayEnvironment: this.props.relay.environment,
            authOptions: {
                ga: {
                    label: 'save search - dealer storefront page',
                },
            },
        }).then(({ userId }) => {
            storefrontFollowDealer(this.props.relay.environment, {
                userId,
                sellerPk: get(this.props, 'seller.serviceId'),
                favoriteId,
            });
            trackFollowSearch({ label: 'top of results', isAdded: !favoriteId });
        });
    }

    render() {
        //headshotPhotoPath may be local path or full url
        const headshotPhotoPath = getPhotoPath(this.props, DEALER_STOREFRONT_HEADSHOT);
        //searchString contains existing mediaService edits
        const searchString = headshotPhotoPath.split('?').pop();
        const queryParams = new URLSearchParams(searchString);
        const cropParams = queryParams.get('crop');
        let headshotPhotoSrc = headshotPhotoPath;
        if (!cropParams) {
            headshotPhotoSrc = getImageSrc(headshotPhotoPath, {
                width: 160,
                height: 160,
                fit: 'crop',
            });
        }

        return (
            <ProfileCover
                disableSticky
                fullStateSize={this.state.headshotSize}
                additionalClasses={getClasses(['cover', 'noPicture'])}
                // Data / Components
                TopRenderer={() => <div className={styles.top} />}
                TabsRenderer={() => (
                    <div className={styles.tabsWrapper}>
                        <StorefrontFollowButton
                            className={styles.follow}
                            seller={this.props.seller}
                            followDealer={this.followDealer}
                        />
                    </div>
                )}
                SocialRenderer={() => (
                    <StorefrontSocial
                        user={get(this.props, 'user') || null}
                        isPreview={get(this.props, 'isPreview')}
                        viewer={this.props.viewer}
                        seller={this.props.seller}
                        followDealer={this.followDealer}
                    />
                )}
                DetailsRenderer={() => (
                    <StorefrontDetails
                        seller={this.props.seller}
                        additionalClasses={getClasses(['details'])}
                    />
                )}
                PictureRenderer={
                    headshotPhotoPath
                        ? () => (
                              <StorefrontHeadshot
                                  imagePath={headshotPhotoSrc}
                                  additionalClasses={getClasses(['headshot'])}
                              />
                          )
                        : null
                }
                coverPhoto={getPhotoPath(this.props, DEALER_STOREFRONT_BANNER)}
            />
        );
    }
}

StorefrontHead.propTypes = {
    relay: PropTypes.object.isRequired,
    isPreview: PropTypes.bool.isRequired,

    // data
    viewer: PropTypes.object.isRequired,
    user: PropTypes.object,
    seller: PropTypes.object.isRequired,
};

export default createFragmentContainer(StorefrontHead, {
    seller: graphql`
        fragment StorefrontHead_seller on Seller {
            serviceId
            favoritesByUser(userIds: $userId) @include(if: $hasUserId) {
                serviceId
            }
            sellerProfile {
                company
            }
            storefrontProfile(storefrontId: $storefrontId) {
                aboutUs
                photos {
                    path
                    type {
                        name
                    }
                }
            }
            ...StorefrontFollowButton_seller
            ...StorefrontSocial_seller
            ...StorefrontDetails_seller
        }
    `,
    user: graphql`
        fragment StorefrontHead_user on User {
            ...StorefrontSocial_user
            serviceId
        }
    `,
    viewer: graphql`
        fragment StorefrontHead_viewer on Viewer {
            ...StorefrontSocial_viewer
        }
    `,
});
